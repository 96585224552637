import React from "react";

import Header from "../partials/Header";
import HeroHome from "../partials/HeroHome";
import FeaturesHome from "../partials/Features";
import FeaturesBlocks from "../partials/FeaturesBlocks";
import Testimonials from "../partials/Testimonials";
import Footer from "../partials/Footer";
import landingpage from "../images/landingpage.png";
import map from "../images/map.png";
import UsedBy from "../partials/UsedBy";
import Benefits from "../partials/Benefits";
import Steps from "../partials/Steps";
import Works from "../partials/Works";
import Integration from "../partials/Integration";
import Questions from "../partials/Questions";

function Home() {
  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      <Header />
      <main className="flex-grow">
        <img
          src={landingpage}
          alt="landingpage"
          className="absolute landing-page-image"
        />
        <HeroHome />
        <UsedBy />

        <FeaturesHome />
        <div className="flex justify-center">
          <img
            src={map}
            alt="map"
            className="absolute backgroun-page-image max-w-6xl mx-auto"
          />
        </div>
        <FeaturesBlocks />
        <Benefits />
        <Testimonials />
        <Steps />
        <Works />
        <Integration />
        <Questions />
      </main>

      <Footer />
    </div>
  );
}

export default Home;
