import React from "react";
import Faq from "react-faq-component";
import arrow from "../images/arrow.png";
const data = {
  rows: [
    {
      title: <b>What is Listus?</b>,
      content: (
        <p className="text-left">
          Listus is an embedded service provider allowing businesses to offer
          crypto exchange listings, smart contract audits, marketing and market
          making to their business customers. All with an embedded CRM platform
          that simplifies the application process for projects.
        </p>
      ),
    },
    {
      title: <b>What pricing plans are available for Listus?</b>,
      content: (
        <p className="text-left">
          Listus is free to use for cryptocurrency projects to submit to as many
          exchanges as they want. We only charge flat fee on top of the listing
          fee dependent on the exchange that has accepted. For Data Platforms
          like Coinmarketcap / Coingecko we charge a flat fee to apply.
        </p>
      ),
    },
    {
      title: <b>Who can benefit from using Listus?</b>,
      content: (
        <p className="text-left">
          Listus is designed for cryptocurrency projects of all sizes, whether
          emerging or established, seeking to list on reputable exchanges,
          onramps or dexs. It also serves cryptocurrency exchanges looking to
          optimize their listing processes and enhance communication with
          projects.
        </p>
      ),
    },
    {
      title: <b>Where can I list?</b>,
      content: (
        <p className="text-left">
          You can list on Onramps like Transak, Moonpay etc or Centralized
          Exchanges like Huobi, MEXC and ByBit or Data Platforms like
          Coinmarketcap and Coingecko.
        </p>
      ),
    },
  ],
};

const styles = {
  // bgColor: 'white',
  titleTextColor: "black",
  rowTitleColor: "black",
  // rowContentColor: 'grey',
  // arrowColor: "red",
};

const config = {
  animate: true,
  arrowIcon: <img src={arrow} alt="arrow" />,
  //   tabFocus: true,
};

function Questions() {
  return (
    <section className="relative">
      <div className="absolute left-0 right-0 bottom-0 m-auto w-px p-px h-20 transform translate-y-1/2"></div>

      <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20">
          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
            <h2 className="h2 mb-4">
              <span className="text-blue-500">Questions?</span> Look here
            </h2>
          </div>
          <div className="max-w-4xl mx-auto text-center pb-12 md:pb-16 flex justify-between">
            <Faq data={data} styles={styles} config={config} />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Questions;
