import React from "react";
import listusSS from "../images/listus-ss.png";
import swaptab from "../images/swaptab.png";
import continuous from "../images/continuous.png";
import audit from "../images/audit.png";

function Features() {
  return (
    <section className="relative">
      {/* <div className="absolute left-0 right-0 m-auto w-px p-px h-20 bg-gray-200 transform -translate-y-1/2"></div> */}

      <div className="relative mx-auto px-4 sm:px-6">
        <div className="">
          <div className="max-w-6xl mx-auto text-center pb-12 md:pb-16">
            <h1 className="h2 mb-4 text-blue-500">Listus</h1>
            <p className="text-gray-600 text-base">
              Listus, powered by Rampnalysis, revolutionizes cryptocurrency
              operations. Beyond streamlining Centralized Exchange listings, we
              facilitate seamless smart contract audits, market making, and
              strategic marketing. As a comprehensive CRM platform, Listus
              prioritizes efficiency, security, and robust support, propelling
              the crypto landscape into a new era of integrated solutions
            </p>
            <div className="flex justify-center backgroun-page-image mt-8">
              <img src={listusSS} alt="listusSS" />
            </div>
          </div>
          <div className="flex justify-center">
            <button
              className="used-by-small-card text-white py-3 text-center w-40"
              onClick={() =>
                window.open(
                  "https://app.rampnalysis.com/register?account_name=channel_partner"
                )
              }
            >
              Try it yourself
            </button>
          </div>
          <div className="max-w-6xl mx-auto text-center pb-12 md:pb-16 mt-8">
            <h1 className="h2 mb-4">
              We <span className="text-blue-500">Create</span> Super-Apps
            </h1>
            <h3>Effortlessly add services to your operations</h3>
          </div>
          <div className="max-w-6xl mx-auto text-center pb-12 md:pb-16">
            <div id="main" className="m-8 grid grid-row-4 grid-flow-col gap-1">
              <div className="row-start-2 super-app-card text-white py-5 text-center mr-8">
                <img src={swaptab} alt="swaptab" className="pl-4" />
                <p className="text-black font-medium text-lg mt-6">
                  Embeddable Anywhere
                </p>
                <p className="text-black ">
                  Integrate the Listus widget seamlessly into any platform or
                  website.
                </p>
              </div>
              <div className="row-start-2 super-app-card text-white py-5 text-center mr-8">
                <img src={continuous} alt="continuous" className="pl-4" />
                <p className="text-black font-medium text-lg mt-6">
                  Whitelabeled Customization
                </p>
                <p className="text-black ">
                  Match your brand's aesthetics with customizable design
                  options.
                </p>
              </div>
              <div className="row-start-2 super-app-card text-white py-5 text-center">
                <img src={audit} alt="audit" className="pl-4" />
                <p className="text-black font-medium text-lg mt-6">
                  Diverse Operations{" "}
                </p>
                <p className="text-black ">
                  From listings to audits to marketing - handle it all
                  effortlessly.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

// https://github.com/hermanwikner/react-connect-lines
// https://eliav2.github.io/react-xarrows/

export default Features;
