import React from "react";
import lbank from "../images/lbank.png";
import xt from "../images/xt.png";
import poloniex from "../images/poloniex.png";
import latoken from "../images/latoken.png";
import mexc from "../images/mexc.png";
import Marquee from "react-fast-marquee";

function UsedBy() {
  return (
    <div className="used-by-card">
      <div className="row-start-1 row-span-2 rounded-lg text-3xl ml-8">
        <u className="text-blue-500 font-medium">
          <i>Used By</i>
        </u>
      </div>
      <div id="main" className="m-8 grid grid-row-4 grid-flow-col gap-1 ">
        <Marquee pauseOnHover={true} speed={50} autoFill={true}>
          <div className="used-by-small-card text-white py-6 text-center flex justify-center mr-6 w-64">
            <img src={lbank} alt="lbank" />
          </div>
          <div className="used-by-small-card text-white py-6 text-center flex justify-center mr-6 w-64">
            <img src={xt} alt="xt" />
          </div>
          <div className="used-by-small-card text-white py-6 text-center flex justify-center mr-6 w-64">
            <img src={poloniex} alt="poloniex" />
          </div>
          <div className="used-by-small-card text-white py-6 text-center flex justify-center mr-6 w-64">
            <img src={latoken} alt="latoken" />
          </div>
          <div className="used-by-small-card text-white py-6 text-center flex justify-center mr-6 w-64">
            <img src={mexc} alt="mexc" />
          </div>
        </Marquee>
      </div>
    </div>
  );
}

export default UsedBy;
