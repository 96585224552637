import React from "react";
import VectorDown from "../images/Vector-down.png";
import platform from "../images/platform.png";

function Works() {
  return (
    <section className="relative">
      <div className="absolute left-0 right-0 bottom-0 m-auto w-px p-px h-20  transform translate-y-1/2"></div>

      <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
        <div className="pb-12 md:pb-20">
          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
            <div className="flex justify-center">
              <div>
                <h2 className="h2">HOW IT WORKS</h2>
                <h2 className="h2 mb-4 text-blue-500">Listus Plugins</h2>
              </div>
              <img src={VectorDown} alt="VectorDown" className="mt-4" />
            </div>
            <p className="text-xl">
              “<b>Listus</b> is our modular extensible embedded service
              platform”
            </p>
          </div>
          <div className="overflow-auto">
            <div id="main" className="m-8 grid grid-row-4 grid-flow-col gap-1">
              <div className="col-start-1 row-start-1 used-by-small-card text-white py-5 text-center mr-10 mb-2 w-56">
                Peanut
              </div>
              <div className="col-start-1 row-start-2 used-by-small-card text-white py-5 text-center mr-10 mb-2 w-56">
                Gotbit.io
              </div>
              <div className="col-start-1 row-start-3 used-by-small-card text-white py-5 text-center mr-10 mb-2 w-56">
                Kairon Labs
              </div>
              <div className="col-start-1 row-start-4 used-by-small-card text-white py-5 text-center mr-10 w-56">
                CLS Global
              </div>
              <div className="col-start-1 row-start-5 text-black py-5 text-center mr-10">
                Market Making
              </div>

              <div className="col-start-2 row-start-1 used-by-small-card text-white py-5 text-center mr-10 mb-2 w-56">
                Bitget
              </div>
              <div className="col-start-2 row-start-2 used-by-small-card text-white py-5 text-center mr-10 mb-2 w-56">
                MEXC
              </div>
              <div className="col-start-2 row-start-3 used-by-small-card text-white py-5 text-center mr-10 mb-2 w-56">
                Poloniex
              </div>
              <div className="col-start-2 row-start-4 used-by-small-card text-white py-5 text-center mr-10 w-56">
                + 9 more
              </div>
              <div className="col-start-2 row-start-5 text-black py-5 text-center mr-10">
                Centralized Exchanges
              </div>

              <div className="col-start-3 row-start-1 used-by-small-card text-white py-5 text-center mr-10 mb-2 w-56">
                EAK
              </div>
              <div className="col-start-3 row-start-2 used-by-small-card text-white py-5 text-center mr-10 mb-2 w-56">
                Orion 7
              </div>
              <div className="col-start-3 row-start-3 used-by-small-card text-white py-5 text-center mr-10 mb-2 w-56">
                Blockwiz
              </div>
              <div className="col-start-3 row-start-4 used-by-small-card text-white py-5 text-center mr-10 w-56">
                Icoda.io
              </div>
              <div className="col-start-3 row-start-5 text-black py-5 text-center mr-10">
                Marketing
              </div>

              <div className="col-start-4 row-start-4 used-by-small-card text-white py-5 text-center w-56">
                Rampnalsyis
              </div>
              <div className="col-start-4 row-start-5 text-black py-5 text-center mr-10">
                On-ramps
              </div>
            </div>
            <img
              src={platform}
              alt="platform"
              style={{ minWidth: "1000px" }}
              className="mt-4 backgroun-page-image mb-8"
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Works;
