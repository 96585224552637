import React from "react";
import integration from "../images/integration.png";

function Integration() {
  return (
    <div className="max-w-4xl mx-auto">
      <div className="relative text-center overflow-x-auto">
        <img
          src={integration}
          alt="Integration steps"
          className="w-full"
          style={{ minHeight: "400px" }}
        />

        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 p-8 max-w-2xl">
          <p className="text-white text-2xl md:text-2xl lg:text-3xl">
            Ready to supercharge your operations with the Listus widget?
          </p>
          <button
            className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 mt-5 rounded"
            onClick={() => window.open("https://app.listus.xyz/landing-page")}
          >
            Integrate now
          </button>
          <p className="text-white mt-5 text-sm md:text-base">
            For further inquiries or a live demo,{" "}
            <a
              href="https://app.listus.xyz/landing-page"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-600 underline"
            >
              contact us
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}

export default Integration;
