import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import michael from "../images/michael.png";
import testimonial from "../images/testimonial.jpg";

function Testimonials() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  return (
    <section className="relative">
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20">
          <div className="max-w-3xl mx-auto text-center md:pb-16">
            <h2 className="h2 mb-4 text-blue-500">Testimonials</h2>
            <p className="text-xl text-gray-600">
              Here's what projects and service providers think of us
            </p>
          </div>
          <div className="max-w-3xl mx-auto">
            <Slider {...settings}>
              <div className="flex items-start border-2 border-gray-200 rounded bg-white pb-6">
                <div className="text-center px-12 mx-4 md:mx-0">
                  <div className="flex justify-center py-8">
                    <img
                      className="rounded-full"
                      src={testimonial}
                      width="96"
                      height="96"
                      alt="avatar"
                    />
                  </div>
                  <p className="text-lg font-medium mb-4 text-left">
                    "Getting in touch with exchanges is hard for even the best
                    of projects and not everyone has connections with exchanges.
                  </p>
                  <p className="text-lg font-medium mb-4 text-left">
                    Using Rampnalysis helped us get in touch with multiple
                    exchanges in a very quick and easy way. All we had to do was
                    fill out the form and provide our project and TGE details
                    and then exchanges started to contact us within less than 24
                    hours.
                  </p>
                  <p className="text-lg font-medium mb-4 text-left">
                    Highly recommend projects to use Rampnalysis if you want to
                    get listed and save a ton of time!"
                  </p>
                  <cite className="block font-bold text-lg not-italic mb-1 text-left">
                    Darya Finger
                  </cite>
                  <div className="text-gray-600 text-left">
                    <span>CEO & Co-Founder</span>{" "}
                  </div>
                </div>
              </div>
              <div className="flex items-start border-2 border-gray-200 rounded bg-white pb-6">
                <div className="text-center px-12 mx-4 md:mx-0">
                  <div className="flex justify-center py-8">
                    <img
                      className="rounded-full"
                      src={michael}
                      width="96"
                      height="96"
                      alt="avatar"
                    />
                  </div>
                  <p className="text-lg font-medium mb-4 text-left">
                    "Getting in touch with exchanges is hard for even the best
                    of projects and not everyone has connections with exchanges.
                  </p>
                  <p className="text-lg font-medium mb-4 text-left">
                    Using Rampnalysis helped us get in touch with multiple
                    exchanges in a very quick and easy way. All we had to do was
                    fill out the form and provide our project and TGE details
                    and then exchanges started to contact us within less than 24
                    hours.
                  </p>
                  <p className="text-lg font-medium mb-4 text-left">
                    Highly recommend projects to use Rampnalysis if you want to
                    get listed and save a ton of time!"
                  </p>
                  <cite className="block font-bold text-lg not-italic mb-1 text-left">
                    Michael{" "}
                  </cite>
                  <div className="text-gray-600 text-left">
                    <span>Chief Executive Officer</span>{" "}
                  </div>
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Testimonials;
