import React from "react";
import unified from "../images/unified.png";
import security from "../images/security.png";
import intutive from "../images/intutive.png";
function FeaturesBlocks() {
  return (
    <section className="relative">
      <div className="absolute left-0 right-0 bottom-0 m-auto w-px p-px h-20 transform translate-y-1/2"></div>

      <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20">
          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
            <h2 className="h2 mb-4">
              <span className="text-blue-500">Seamless</span> Solutions
            </h2>
            <p className="text-xl text-black">
              Make finding services easier for your businesses.
            </p>
          </div>
          <div className="max-w-6xl mx-auto text-center pb-12 md:pb-16 flex justify-between overflow-auto">
            <img src={unified} alt="unified" className="mr-6 ml-6" />
            <img src={security} alt="security" className="mr-6" />
            <img src={intutive} alt="intutive" className="mr-6" />
          </div>
        </div>
      </div>
    </section>
  );
}

export default FeaturesBlocks;
