import React from "react";
import benefits from "../images/benefits.png";
import agencies from "../images/agencies.png";
import service from "../images/service.png";
import vc from "../images/vc.png";

function Benefits() {
  return (
    <section className="relative">
      <div className="absolute left-0 right-0 bottom-0 m-auto w-px p-px h-20transform translate-y-1/2"></div>
      <div className="relative max-w-4xl mx-auto px-4 sm:px-6 grid gap-4 md:grid-cols-2">
        <div className="benefits-responsive">
          <img src={benefits} alt="benefits" />
        </div>
        <div>
          <div className="row-start-2 super-app-card text-white py-5 text-center px-6">
            <div className="flex justify-between items-center">
              <img
                src={agencies}
                alt="agencies"
                style={{ height: "74px", width: "74px" }}
              />
              <div className="text-black text-left ml-4">
                <p className="font-bold">Agencies</p>
                <p>
                  Offer clients a unified portal for all their crypto service
                  needs.
                </p>
              </div>
            </div>
          </div>

          <div className="row-start-2 super-app-card text-white py-5 text-center px-6 mt-6">
            <div className="flex justify-between items-center">
              <img
                src={service}
                alt="service"
                style={{ height: "74px", width: "74px" }}
              />
              <div className="text-black text-left ml-4">
                <p className="font-bold">Service Providers</p>
                <p>
                  Upsell services which are necessary objectives for your
                  clients.
                </p>
              </div>
            </div>
          </div>

          <div className="row-start-2 super-app-card text-white py-5 text-center px-6 mt-6">
            <div className="flex justify-between items-center">
              <img
                src={vc}
                alt="vc"
                style={{ height: "74px", width: "74px" }}
              />

              <div className="text-black text-left ml-4">
                <p className="font-bold">Accelerators & VCs</p>
                <p>
                  Enhance portfolio management with efficient listing and
                  auditing operations.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Benefits;
