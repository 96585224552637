import React from "react";
import stepBg from "../images/stepbg.png";
import step from "../images/step.png";

function Steps() {
  return (
    <div className="overflow-auto">
      <div className="flex justify-center">
        <div className="absolute text-4xl text-blue-500 font-medium text-center p-8">
          <p>Steps</p>
          <p className="text-lg text-white">How to Get Started</p>
        </div>
        <img
          src={step}
          alt="step"
          className="absolute mt-10"
          // style={{ minWidth: "1000px" }}
        />
      </div>
      <div className="flex justify-center">
        <img
          src={stepBg}
          alt="stepBg"
          className="landing-page-image"
          style={{ height: "747px" }}
        />
      </div>
      <div className="relative start-button">
        <button
          className="start-now-button"
          onClick={() =>
            window.open(
              "https://app.rampnalysis.com/register?account_name=channel_partner"
            )
          }
        >
          Start now
        </button>
      </div>
    </div>
  );
}

export default Steps;
